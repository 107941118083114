
import { Component, Vue } from 'vue-property-decorator'
import { pInteger } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { TimingPlan } from '@/types/irrigation'
import { timeIrrigationDesc } from '@/utils/config'

@Component
export default class IrrigationTimingPlanAdd extends Vue {
  // 正整数校验，小于20
  numLessTen = function (rule: object, value: string, callback: Function) {
    const reg = /^[1-9][0-9]*$/
    if (!reg.test(value) || Number(value) < 2 || Number(value) > 20) {
      return callback(new Error('请输入2到20数值'))
    } else {
      return callback()
    }
  }

  private info: TimingPlan = {
    planName: '',
    projectAreaId: '',
    projectId: '',
    dateRange: [],
    executeTime: '',

    concurrentNum: '',
    intervalDays: '',
    keepLength: ''
  }

  private rules = {
    planName: [
      { required: true, message: '请输入计划名称', trigger: ['blur', 'change'] }
    ],
    projectAreaId: [
      { required: true, message: '请选择喷灌区域', trigger: ['blur', 'change'] }
    ],
    dateRange: [
      { required: true, message: '请选择计划时间段', trigger: ['blur', 'change'] }
    ],
    executeTime: [
      { required: true, message: '请选择开启时间', trigger: ['blur', 'change'] }
    ],

    concurrentNum: [
      { required: true, message: '请输入电磁阀一次同时开启的个数，范围1个到3个', trigger: ['blur', 'change'] }
    ],
    intervalDays: [
      { required: true, message: '请输入喷灌频率', trigger: ['blur', 'change'] },
      { validator: pInteger, trigger: ['blur', 'change'] }
    ],
    keepLength: [
      { required: true, message: '请输入电磁阀开启时长，时长范围2分钟到20分钟', trigger: ['blur', 'change'] },
      { validator: this.numLessTen, trigger: ['blur', 'change'] }
    ]
  }

  private areaList = []
  private submitShow = false

  get timeIrrigationDesc () {
    return timeIrrigationDesc
  }

  created () {
    this.getArea()
    if (this.$route.params.id) {
      this.getPlanDetail(this.$route.params.id)
    }
  }

  getPlanDetail (id: string) {
    this.$axios.get(this.$apis.irrigation.irrigationPlanDetail, {
      planId: id
    }).then((res) => {
      this.info = {
        planId: res.planId,
        planName: res.planName,
        projectAreaId: res.projectAreaId,
        projectId: res.projectId,
        dateRange: [res.startDate, res.endDate],
        executeTime: res.executeTime,

        concurrentNum: res.concurrentNum,
        intervalDays: res.intervalDays,
        keepLength: res.keepLength
      }
    })
  }

  getArea () {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId: this.$route.query.projectId,
      isIdimArea: '1',
      notAllArea: '1'
    }).then((res) => {
      this.areaList = res.list || []
    })
  }

  areaChange () {
    // this.info.irrigationDevice.valveDevices = []

  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const info = {
          planId: this.info.planId || null,
          planName: this.info.planName,
          projectAreaId: this.info.projectAreaId,
          projectId: this.$route.query.projectId,
          startDate: this.info.dateRange[0],
          endDate: this.info.dateRange[1],
          executeTime: this.info.executeTime,
          concurrentNum: this.info.concurrentNum,
          intervalDays: this.info.intervalDays,
          keepLength: this.info.keepLength
        }
        this.$axios.post(this.$apis.irrigation.irrigationPlanAdd, {
          ...info,
          planType: '1'
        }).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.goBack()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  goBack () {
    this.$router.push({
      path: '/irrigationmodelsetting/detail/timingplan',
      query: { projectId: this.$route.query.projectId }
    })
  }
}
